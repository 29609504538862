import React from 'react'
import './header.css'
import CTA from './cta'
import HeaderSocials from './HeaderSocials'
// import ME from "../../assets/Picture1.png";

const abount = props => {
    return (
        <header id="home">
            <div className="container header_container">
                <h5>Hello I'm</h5>
                <h1>Senele Cebekhulu</h1>
                <h5 className="tex-light">Software Developer</h5>
                <CTA/>
                <HeaderSocials/>
                <div className="me">
                    {/* <img src={ME} alt="me"/> */}
                </div>
                <a href="#contact" className="scroll_down">Scroll Down</a>
            </div>
        </header>
    )
}


export default abount
