import React from 'react'
import './portfio.css'
import Pic1 from '../../assets/p1.png'
import Pic2 from '../../assets/p2.png'
import creativeCloudy from '../../assets/Screenshot 2023-02-01 222929.png'


const abount = props => {
    return (
        <section id="portfio">
            <h5>What Skills I Have</h5>
            <h2>My Experience</h2>
            <div className="container portfolio_container">
                <article className="portfolio_item">
                    <div className="portfolio_item-image">
                        <img src={creativeCloudy} alt=""/>
                    </div>
                    <h3>Creative Cloud - clone</h3>
                    <div className="portfolio_item-cta">
                        <a href="http://github.com" className="btn">Github</a>
                        <a href="https://2a8af4a6.creative-cloudy.pages.dev
" className="btn">Live Demo</a>
                    </div>
                </article>

                <article className="portfolio_item">
                    <div className="portfolio_item-image">
                        <img src={Pic1} alt=""/>
                    </div>
                    <h3>This is item title</h3>
                    <div className="portfolio_item-cta">
                        <a href="http://github.com" className="btn">Github</a>
                        <a href="http://github.com" className="btn">Live Demo</a>
                    </div>
                </article>
                <article className="portfolio_item">
                    <div className="portfolio_item-image">
                        <img src={Pic2} alt=""/>
                    </div>
                    <h3>This is item title</h3>
                    <div className="portfolio_item-cta">
                        <a href="http://github.com" className="btn">Github</a>
                        <a href="http://github.com" className="btn">Live Demo</a>
                    </div>
                </article>
                <article className="portfolio_item">
                    <div className="portfolio_item-image">
                        <img src={Pic1} alt=""/>
                    </div>
                    <h3>This is item title</h3>
                    <div className="portfolio_item-cta">
                        <a href="http://github.com" className="btn">Github</a>
                        <a href="http://github.com" className="btn">Live Demo</a>
                    </div>
                </article>
                <article className="portfolio_item">
                    <div className="portfolio_item-image">
                        <img src={Pic2} alt=""/>
                    </div>
                    <h3>This is item title</h3>
                    <div className="portfolio_item-cta">
                        <a href="http://github.com" className="btn">Github</a>
                        <a href="http://github.com" className="btn">Live Demo</a>
                    </div>
                </article>
                <article className="portfolio_item">
                    <div className="portfolio_item-image">
                        <img src={Pic1} alt=""/>
                    </div>
                    <h3>This is item title</h3>
                    <div className="portfolio_item-cta">
                        <a href="http://github.com" className="btn">Github</a>
                        <a href="http://github.com" className="btn">Live Demo</a>
                    </div>
                </article>
                
            </div>
        </section>
    )
}


export default abount
