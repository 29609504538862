import React from 'react'
import './about.css'
import {FaAward} from 'react-icons/fa';
import {VscFolderLibrary} from 'react-icons/vsc';
import Image from '../../assets/se.png'
const abount = props => {
    return (
        <section id="about">
            <h5>Get to know</h5>
            <h2>About Me</h2>
            <div className="about_container container">
                <div className="about_me">
                    <div className="about_me-img">
                        <img src={Image} alt="ne" width="100%"/>
                    </div>
                </div>

                
                <div className="about_content">
                    <div className="about_cards">
                        <article className="about_card">
                            <FaAward className="about_icon"/>
                            <h5>Experience</h5>
                            <small>3+ Years Working</small>
                        </article>
                        {/* <article className="about_card">
                            <FaAward/>
                            <h5>Experience</h5>
                            <small>3+ Years Working</small>
                        </article> */}
                        <article className="about_card">
                            <VscFolderLibrary className="about_icon"/>
                            <h5>Project</h5>
                            <small>23+ Completed Projects</small>
                        </article>
                    </div>
                    
                    <p>I am passionate about building excellent software that improves the lives of those around me. 
                        I specialize in creating software for clients ranging from individuals and small-businesses all
                         the way to large enterprise corporations. What would you do if you had a software expert available at your fingertips?
                    </p>
                    <a href="#contact" className="btn btn-primary">Let's Talk</a>
                </div>
            </div>
        </section>
    )
}


export default abount
