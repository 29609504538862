import React from 'react'
import './experience.css'
import { BsPatchCheckFill } from 'react-icons/bs';

const abount = props => {
    return (
        <section id="experience">
            <h5>What Skills I Have</h5>
            <h2>My Experience</h2>

            <div className="container experience_container">
                <div className="experience_fontend">
                    <h3>Frontend Development</h3>
                    <div className="experience_content">
                        <article className="experience_details">
                            <BsPatchCheckFill className="experience_details-icon" />
                            <div>
                                <h4>HTML && CSS</h4>
                                <small className="text-light">Experienced</small>
                            </div>
                            
                        </article>
                        <article className="experience_details">
                            <BsPatchCheckFill className="experience_details-icon" />
                            <div>
                                <h4>JavaScript</h4>
                                <small className="text-light">Experienced</small>
                            </div>
                            
                        </article>
                        <article className="experience_details">
                            <BsPatchCheckFill className="experience_details-icon" />
                            <div>
                                <h4>Bootstrap</h4>
                                <small className="text-light">Experienced</small>
                            </div>
                            
                        </article>
                        <article className="experience_details">
                            <BsPatchCheckFill className="experience_details-icon" />
                            <div>
                                <h4>Vuejs</h4>
                                <small className="text-light">Experienced</small>
                            </div>
                            
                        </article>
                        <article className="experience_details">
                            <BsPatchCheckFill className="experience_details-icon" />
                            <div>
                                <h4>Reactjs</h4>
                                <small className="text-light">Beginer</small>
                            </div>
                            
                        </article>
                        <article className="experience_details">
                            <BsPatchCheckFill className="experience_details-icon" />
                            <div>
                                <h4>Angular</h4>
                                <small className="text-light">Beginer</small>
                            </div>
                            
                        </article>
                    </div>

                </div>
                <div className="experience_backend">
                    <h3>Backend Development</h3>
                    <div className="experience_content">
                        <article className="experience_details">
                            <BsPatchCheckFill className="experience_details-icon" />
                            <div>
                                <h4>.Net</h4>
                                <small className="text-light">Experienced</small>
                            </div>
                            
                        </article>
                        <article className="experience_details">
                            <BsPatchCheckFill className="experience_details-icon" />
                            <div>
                                <h4>C#</h4>
                                <small className="text-light">Experienced</small>
                            </div>
                            
                        </article>
                        <article className="experience_details">
                            <BsPatchCheckFill className="experience_details-icon" />
                            <div>
                                <h4>PHP</h4>
                                <small className="text-light">Experienced</small>
                            </div>
                            
                        </article>
                        <article className="experience_details">
                            <BsPatchCheckFill className="experience_details-icon" />
                            <div>
                                <h4>Nodejs</h4>
                                <small className="text-light">Experienced</small>
                            </div>
                            
                        </article>
                        <article className="experience_details">
                            <BsPatchCheckFill className="experience_details-icon" />
                            <div>
                                <h4>Expressjs</h4>
                                <small className="text-light">Beginer</small>
                            </div>
                            
                        </article>
                        
                    </div>
                </div>
            </div>
        </section>
    )
}


export default abount
