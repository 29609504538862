import React from 'react'
import './contacts.css'
import { MdOutlineEmail } from 'react-icons/md'
import { AiOutlineSkype } from 'react-icons/ai'
import { BsWhatsapp } from 'react-icons/bs'

const abount = props => {
    return (
        <section id="contact">
            <h5>Get In Touch</h5>
            <h2>Contact Me</h2>

            <div className="container contact_container">
                <div className="contact_options">
                    <div className="contact_option">
                        <article >
                            <MdOutlineEmail className="contact_option-icon"/>
                            <h4>Email</h4>
                            <h5>csenele@gmail.com</h5>                            
                            <a href="mailto:csenele@gmail.com"  className="">Send a message</a>
                            {/* END OF CONTACT OPTIONS */}
                        
                        </article>
                    </div>
                    <div className="contact_option">
                        <article >
                            <AiOutlineSkype className="contact_option-icon"/>
                            <h4>Skyper</h4>
                            <h5>+27 72 1646 919</h5>                       
                            <a href="skype:echo123?chat"  className="">Send a message</a>
                            {/* END OF CONTACT OPTIONS */}
                            
                        </article>
                    </div>
                    <div className="contact_option">
                        <article >
                            <BsWhatsapp className="contact_option-icon" />
                            <h4>Whatsapp</h4>
                            <h5>+27 72 1646 919</h5>                            
                            <a href="https://api.whatsapp.com/send?phone=+27721646919"  className="">Send a message</a>
                            {/* END OF CONTACT OPTIONS */}
                    
                        </article>
                    </div>
                </div>
                <form action="">

                    <input type="text" name='name' placeholder="Your Full Name" required />
                    <input type="email" name='email' placeholder="Your Email" required />
                    <textarea name="message" rows="7" placeholder="Type Your Message" required></textarea> 
                    <button type="submit" className="btn">Send</button>  

                </form>
            </div>
        </section>
    )
}


export default abount
