import React from 'react'
import './footer.css'
import {FaFacebookF} from "react-icons/fa";
import {FiInstagram} from "react-icons/fi";
import {IoLogoTwitter} from "react-icons/io";

const abount = props => {
    return (
        <footer>
            <a href="#home" className="footer_logo">Senele</a>

            <ul className="permalinks">
                <li><a  href="#home">Home</a></li>
                <li><a  href="#about">About</a></li>
                <li><a  href="#expeirience">Expeirience</a></li>
                <li><a href="#portfolio">Portfolio</a></li>
                <li><a  href="#testimonials">Testimonials</a></li>
                <li><a  href="#contact">Contact</a></li>
                
            </ul>
            
            <div className="footer_socials">
                <a href="http://facebook.com"><FaFacebookF/></a>
                <a href="http://instagram.com"><FiInstagram/></a>
                <a href="http://twitter.com"><IoLogoTwitter/></a>
            </div>

            <div className="footer_copyright">
                <small>&copy; SENELE CEBEKHULU. All rights reserved.</small>
            </div>

        </footer>
    )
}


export default abount
