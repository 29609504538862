import React from 'react'
import Header from './components/header/header'
import Nav from './components/nav/nav'
import About from './components/about/abount'
import Experience from './components/experience/experience'

import Portfolio from './components/portfio/portfio'
import Contacts from './components/contacts/contacts'
import Footer from './components/footer/footer'
const App = () => {
    return (
        <>
            <Header/>
            <Nav />
            <About/>
            <Experience/>
            <Portfolio/>
            <Contacts/>
            <Footer/>
        </>
    )
}



export default App
