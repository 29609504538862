import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
// import {BsLinkedin} from 'react-icons/fa'


const HeaderSocials = props => {
    return (
        <div className="header_socials">
            <a href="https://linkedin.com/in/senele-cebekhulu-74b9a0144" rel="noreferrer" target="_blank"><span class="sr-only">some text that relates to your link</span><BsLinkedin/></a>
            <a href="https://github.com" target="_blank" rel="noreferrer" ><span class="sr-only">some text that relates to your link</span><FaGithub/></a>
            
            
        </div>
    )
}



export default HeaderSocials
